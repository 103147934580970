$(document).ready(function () {


	$(".login_head").click(function () {
		$("#login-overlay").css('display', 'block');
	});
	$(".register_head").click(function () {
		$("#register-overlay").css('display', 'block');
	});



	var page_name = document.location.pathname;

	if (page_name == '/rentals.html') {
		$("body").addClass("inner-page");
		$(".agentinfoblock").hide();
		$(".hero-slider").addClass("inner-banner");
		$(".maindiv").addClass("inner-wrap");
		$(".searchform").addClass("row");
	}



});






			