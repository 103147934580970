$(document).ready(function () {


    var hei = $(".header-height").height() + 40;
	$(".banner-outside-wrap").css("height",hei);
	$(".banner-outside-inner-wrap").css("height",hei);
	$(".banner-fix-height").css("height",hei);
	
	//Wrap navigation to more dropdown link
	$('.menu ul').navwrap();

	if ($(".agentpage") != undefined) {
		$(".the_contact_form").insertAfter(".agentpage");
	}

	equalheight = function (container) {
		var currentTallest = 0,
			   currentRowStart = 0,
			   rowDivs = new Array(),
			   $el,
			   topPosition = 0;

		$(container).each(function () {

			$el = $(this);
			$($el).height('auto')
			topPostion = $el.position().top;

			if (currentRowStart != topPostion) {
				for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
					rowDivs[currentDiv].height(currentTallest);
				}
				rowDivs.length = 0; // empty the array
				currentRowStart = topPostion;
				currentTallest = $el.height();
				rowDivs.push($el);
			} else {
				rowDivs.push($el);
				currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
			}
			for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
				rowDivs[currentDiv].height(currentTallest);
			}
		});
	}

	$(window).load(function () {
		equalheight('.menu, #sidesearch, .featured_listings, .agentheader, .footer_copy_container');
		//$(".agentinfoblock").css('background-color','rgba(255, 255, 255, 0.85)');
		//$(".agentinfoblock").css('background-image','url("/templates/template_16/images/agentboardbg.png")');
	});
});


$(document).ready(function () {


	$("nav .menu-column").show();
	//Agents carousel - homepage

	if ($(".bidx_image_list ol").length > 0) {

		$(".bidx_image_list ol").carouFredSel({

			circular: true,

			infinite: false,

			align: "left",

			width: "100%",

			height: 75,

			items: {

				width: "100px",

				height: "auto"

			},

			scroll: {

				items: 1,

				duration: 1000

			},

			prev: ".prev",

			next: ".next",

		});

	}



	$("li.bidx_image_list img").click(function (e) {

		$(".bidx_image_main").attr('src', $(this).attr('src'));

	});



	//Agents carousel - homepage

	if ($(".detail_mini .bidx_image_list ol").length > 0) {

		$(".detail_mini .bidx_image_list ol").carouFredSel({

			circular: true,

			infinite: false,

			align: "left",

			width: "100%",

			height: 55,

			items: {

				width: "75px",

				height: "auto"

			},

			scroll: {

				items: 1,

				duration: 1000

			},

			prev: ".prev",

			next: ".next",

			responsive: true

		});

	}



	$("li.bidx_image_list img").mouseover(function (e) {

		$(".bidx_image_main").attr('src', $(this).attr('src'));

	});







	// //Mobile navigation
	/*
	 var $cloneNav = $(".header-right li, .top_nav li").not('.close_icon, .nav-wrap').clone();
	 
	 $.each($cloneNav, function() {
	 
	 $(this).find('span').remove();
	 
	 $(this).find('i').remove();
	 
	 $(this).removeAttr('class');
	 
	 });
	 
	 $("header").prepend('<ul class="mobile-nav"></ul>');
	 
	 $cloneNav.appendTo(".mobile-nav");
	 
	 $('.mobile-nav').slicknav();
	 */


	//to clone detail page comments

	var detailComment = $(".detail_comment").clone();

	var detailCommentprint = $(".detail_comment").clone();

	$(".detail_comment").remove();

	var insertAfterEl = $("#detailprop ul.col2");
	$(detailComment).insertAfter(insertAfterEl);

	$(detailCommentprint).insertAfter($("#detailprop")).addClass("detailCommentprint");



	var commentText = $(detailCommentprint).find("li").text();

	if (commentText.length > 900) {

		commentText = commentText.slice(0, 900) + " ... ";

		$(detailCommentprint).find("li").text(commentText);

	}



	//to add notes heading on flyer

	$(".notes-print").prepend("<h2 class='notes'>Contact Info</h2>");



	if ($(".testimonial-list-container").length > 0) {

		$(".testimonial-list-container .testimonial").show();

		$(".testimonial-list-container").carouFredSel({

			circular: true,

			infinite: false,

			align: "left",

			height: 115,

			scroll: {

				items: 1,

				duration: 1000

			},

			pagination: "#foo2_pag"

		});

	}



	//check if homepage do not have content

	if ($.trim($(".pagebody").html()) == '') {

		$(".content-block").hide();

	}



});

$(document).ready(function () {

	var path = document.location.pathname;
	$(".agentinfoblock ol li, .footer .agentheader li").each(function () {
		var str = $(this).text();
		if (str.indexOf("Brokerage Office") >= 0) {
			$(this).addClass("brkroffice");
		}
		if (str.indexOf("Home") >= 0) {
			$(this).addClass("homecell");
		}
		if (str.indexOf("Office") >= 0) {
			$(this).addClass("brkroffice");
		}
		if (str.indexOf("Text") >= 0) {
			$(this).addClass("brkrtext");
		}
		if (str.indexOf("Mobile") >= 0) {
			$(this).addClass("brkrmobile");
		}
		if (str.indexOf("Cell") >= 0) {
			$(this).addClass("brkrcell");
		}
	});
});
